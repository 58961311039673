<template>
  <v-dialog
    :value="value"
    max-width="600px"
    @click:outside="handleClose"
    @keydown.esc="handleClose"
  >
    <v-card :loading="pending">
      <v-card-title class="text-h5 rm-dialog-title"
        >Проект #{{ projectId }}</v-card-title
      >

      <v-card-text v-if="project" class="pa-5 black--text text-body-1">
        <project-card :key="project.id" :project="project" />

        <v-card v-if="project.artefacts.length && artefactTypes">
          <v-toolbar color="blue-grey darken-2 mt-5" dark dense flat>
            <v-toolbar-title class="white--text">Артефакты </v-toolbar-title>
          </v-toolbar>
          <v-card-text class="black--text text-body-1">
            <artefact-view
              v-for="item in project.artefacts"
              :id="item.id"
              :key="item.id"
              :type="item.type"
              :value="item.value"
              :types="artefactTypes"
              class="artefact"
            />
          </v-card-text>
        </v-card>
      </v-card-text>

      <v-card-text v-if="error" class="error--text">
        <p>{{ error }}</p>
      </v-card-text>

      <v-card-actions class="pa-4">
        <v-btn color="primary" @click="handleClose">Закрыть</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ProjectCard from "@/components/contact/ProjectCard";
import ArtefactView from "@/components/contact/ArtefactView";
import { talentClient } from "@/api";
export default {
  name: "ProjectDialog",
  components: { ArtefactView, ProjectCard },
  props: {
    projectId: {
      type: Number,
    },
    value: Boolean,
  },
  data() {
    return {
      pending: false,
      project: null,
      error: null,
    };
  },
  computed: {
    talentId() {
      return +this.$route.params.id;
    },
    artefactTypes() {
      return this.$store.state.talent.artefactTypes;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val && this.projectId) {
          this.init();
        }
      },
    },
  },
  methods: {
    async init() {
      this.pending = true;
      try {
        this.$store.dispatch("talent/getArtefactTypes");
        await this.getProject();
      } catch (error) {
        this.error = error.message;
        console.log(error);
      }
      this.pending = false;
    },
    async getProject() {
      const { data } = await talentClient({
        method: "GET",
        url: `/users/${this.talentId}/projects/${this.projectId}/`,
      });
      if (data) {
        this.project = data;
      }
    },
    handleClose() {
      this.$emit("input", false);
      this.resetData();
    },
    resetData() {
      this.pending = false;
      this.project = null;
      this.error = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.artefact + .artefact {
  padding-top: 10px;
  border-top: 1px solid #c0c0c0;
  margin-top: 10px;
}
</style>
