<template>
  <div>
    <projects-section
      :key="updateKey"
      :projects="projects"
      @setPage="getProjects"
      @openProjectDialog="openProjectDialog"
    />
    <project-dialog v-model="projectDialog" :project-id="selectedProject" />
  </div>
</template>
<script>
import { ProjectsSection } from "@/components/contact/sections";
import { initialListingModel } from "@/utils";
import { talentClient } from "@/api";
import ProjectDialog from "@/components/dialogs/ProjectDialog";
export default {
  name: "ContactProjectsPage",
  components: {
    ProjectsSection,
    ProjectDialog,
  },
  data() {
    return {
      projects: initialListingModel(10),
      selectedProject: null,
      projectDialog: false,
      updateKey: 0,
    };
  },
  metaInfo() {
    return {
      title: `Карточка контакта / Проекты`,
    };
  },
  computed: {
    talentId() {
      return +this.$route.params.id;
    },
  },
  activated() {
    this.getProjects(this.projects.page);
    this.updateKey += 1;
  },
  methods: {
    async getProjects(page = 1) {
      if (this.projects.pending) return;
      const { talentId } = this;
      this.projects.pending = true;
      this.projects.error = "";

      try {
        const { data } = await talentClient({
          method: "GET",
          url: `/users/${talentId}/projects/`,
          params: {
            limit: this.projects.limit,
            offset: (page - 1) * this.projects.limit,
          },
        });
        this.projects = {
          ...this.projects,
          list: data.results,
          total: data.count,
          pagesCount: Math.ceil(data.count / this.projects.limit),
          page,
        };
      } catch (error) {
        this.projects.error = error.message;
      }
      this.projects.pending = false;
    },
    openProjectDialog(projectId) {
      this.selectedProject = projectId;
      this.projectDialog = true;
    },
  },
};
</script>
