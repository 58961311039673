<template>
  <div v-if="currentType" class="art-card">
    <div class="art-card__content">
      <div class="art-card__title">{{ currentType.title }}</div>

      <!-- Файл  -->
      <div v-if="formType === TYPE_FILE_LABEL">
        <a
          v-if="fileInfo"
          :href="fileInfo.datafile"
          :download="fileInfo.name"
          target="_blank"
          class="link"
          rel="noopener noreferrer"
        >
          <span v-if="fileInfo">{{ fileInfo.name }}</span>
        </a>
        <span v-else>{{ value }}</span>
      </div>

      <!-- Ссылка --->
      <a
        v-else-if="formType === TYPE_LINK_LABEL"
        :href="value"
        target="_blank"
        class="link"
        rel="noopener noreferrer"
      >
        <span>{{ value }}</span>
      </a>

      <!-- Строка -->
      <div v-else-if="formType === TYPE_STRING_LABEL">
        <div
          ref="overlowContent"
          class="art-card__text"
          :class="{ 'is-open': isOpen }"
        >
          <div>{{ value }}</div>
        </div>
        <a
          v-if="isOverflow"
          href="#"
          class="link link--pseudo"
          @click.prevent="isOpen = !isOpen"
          >{{ isOpen ? "Скрыть" : "Показать" }}</a
        >
      </div>

      <!-- Кастомный тип -->
      <div v-else-if="formType === TYPE_CUSTOM_LABEL">
        <v-row>
          <v-col cols="12" xs="12" md="2">
            <div>Название:</div>
          </v-col>
          <v-col cols="12" xs="12" md="10">
            {{ value.title }}
          </v-col>
        </v-row>
        <v-row class="row">
          <v-col cols="12" xs="12" md="2">
            <div>Ссылка:</div>
          </v-col>
          <v-col cols="12" xs="12" md="10">
            <a
              :href="value.link"
              target="_blank"
              class="link"
              rel="noopener noreferrer"
            >
              <span>{{ value.link }}</span>
            </a>
          </v-col>
        </v-row>
        <v-row class="row">
          <v-col cols="12" xs="12" md="2">
            <div>Описание:</div>
          </v-col>
          <v-col cols="12" xs="12" md="10">
            <div
              ref="overlowContent"
              class="art-card__text"
              :class="{ 'is-open': isOpen }"
            >
              <div>{{ value.description }}</div>
            </div>

            <a
              v-if="isOverflow"
              href="#"
              class="link link--pseudo"
              @click.prevent="isOpen = !isOpen"
              >{{ isOpen ? "Скрыть" : "Показать" }}</a
            >
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import {
  FIELD_TYPE_LABELS,
  TYPE_CUSTOM_LABEL,
  TYPE_FILE_LABEL,
  TYPE_LINK_LABEL,
  TYPE_STRING_LABEL,
} from "@/constants";
import { talentClient } from "@/api";
export default {
  name: "ArtefactView",
  props: {
    type: {
      type: Number,
      required: true,
    },
    value: {
      type: [String, Number, Object],
    },
    types: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
      fileInfo: null,
      isOverflow: false,
      TYPE_CUSTOM_LABEL,
      TYPE_FILE_LABEL,
      TYPE_LINK_LABEL,
      TYPE_STRING_LABEL,
    };
  },
  computed: {
    formType() {
      return FIELD_TYPE_LABELS[this.currentType?.field_type];
    },
    currentType() {
      const { types } = this;
      if (!types) return;
      return types.find((n) => n.id === this.type);
    },
  },
  mounted() {
    if (this.formType === "file") {
      this.getFileInfo();
    }
    if (["custom", "string"].includes(this.formType)) {
      this.checkOverflow();
    }
  },
  methods: {
    async getFileInfo() {
      try {
        const { data } = await talentClient({
          method: "GET",
          url: `/files/${this.value}/`,
        });
        this.fileInfo = data;
      } catch (error) {
        console.log("error", error);
      }
    },
    checkOverflow() {
      const el = this.$refs.overlowContent;
      if (el) {
        this.isOverflow = el.scrollHeight > el.offsetHeight;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.art-card {
  &__content {
    flex-grow: 1;
    min-width: 1px;
    padding-right: 10px;
  }

  &__title {
    font-weight: bold;
    margin-bottom: 5px;
  }

  &__text {
    line-height: 1.5;
    max-height: 1.5 * 2em;
    overflow: hidden;

    &.is-open {
      max-height: 600px;
      overflow: auto;
    }
  }
}
</style>
